<template>
  <!-- <div>视频操作记录</div> -->
  <el-container class="VideoOperationRecord">
    <el-header height="auto">
      <div class="filter-pane">
        <label>选择时间</label>
        <DataRangePicker type="date"
          size="small"
          @change="onChoiceDate"
          :picker="true"></DataRangePicker>
      </div>
      <div class="filter-pane reportTree">
        <label>车组车辆</label>
        <SelectGroupTree @on-select="onSelect"
          @on-vehicle="onConfirm"
          @dbClick="dbConfirm"
          :multipleProp="{
            noGroup: true, // 是否可选车组
            groupCheckBox: false, //车组多选
            vehicleCheckBox: false, //车辆多选
          }"
          class="TreeWidth"
          @clear="ClearSearchParams"></SelectGroupTree>

        <label>视频类型：</label>
        <el-select v-model="searchParams.videoType"
          placeholder="视频类型"
          style="width:120px;">
          <el-option label="全部"
            value></el-option>
          <el-option label="实时视频"
            value="实时视频"></el-option>
          <el-option label="回放视频"
            value="回放视频"></el-option>
          <el-option label="实时对讲"
            value="实时对讲"></el-option>
          <el-option label="实时音视频"
            value="实时音视频"></el-option>
        </el-select>

        <label style="margin:0 0 0 10px;width:45px">通道：</label>
        <el-select v-model="searchParams.channel"
          placeholder="通道"
          style="width:90px">
          <el-option v-for="item in channlList"
            :key="item.value"
            :label="item.label"
            :value="item.value"></el-option>
        </el-select>

        <label>操作用户：</label>
        <el-input v-model="searchParams.username"
          placeholder="请输入用户名"
          style="width:120px"></el-input>
      </div>
      <div class="oper-btns">
        <el-button size="mini"
          type="primary"
          style="font-size: 14px;width:80px;height:32px"
          :disabled="tableLoading"
          @click="onSearchClick(1)">搜索</el-button>

        <Export style="display: inline-block;width:80px;"
          title="视频操作记录"
          :disabled="!data.length"
          exportUrl="videoOperate/operateRecord.do"
          :params="{
              ...this.searchParams,
              pageSize:10000,
              pageNumber:1
            }"></Export>
      </div>
    </el-header>
    <el-main class="reportTable">
      <el-table v-loading="tableLoading"
        height="calc(100% - 45px)"
        border
        size="mini"
        :data="data">
        <el-table-column type="index"
          width="70"
          label="序号"></el-table-column>
        <el-table-column prop="userName"
          label="操作用户"></el-table-column>
        <el-table-column prop="plate"
          label="设备名称"></el-table-column>
        <el-table-column prop="terminalType"
          label="终端类型"></el-table-column>
        <el-table-column prop="plateColor"
          label="车牌颜色"></el-table-column>
        <el-table-column prop="groupName"
          label="车组"></el-table-column>
        <el-table-column prop="terminalNo"
          label="终端编号"
          width="220"></el-table-column>
        <el-table-column prop="playTimeStr"
          label="播放时长（秒）"></el-table-column>
        <el-table-column prop="playNum"
          label="播放次数"></el-table-column>
        <el-table-column min-width="100"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text"
              @click="CheckInfo(scope)">明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"
        style="text-align: center; margin: 10px 10px 0 10px;"></el-pagination>
    </el-main>

    <el-dialog title="视频操作记录明细"
      :visible.sync="dialogVisible"
      width="75%"
      @close="closeInfo"
      class="reportTable reportDialog VideoInfo">
      <el-table v-loading="tableLoading_info"
        size="mini"
        :data="data_info"
        height="70vh">
        <el-table-column type="index"
          width="60"
          label="序号"></el-table-column>
        <el-table-column prop="userName"
          width="120"
          label="操作用户"></el-table-column>
        <el-table-column prop="frontEndType"
          width="80"
          label="播放类型"></el-table-column>
        <el-table-column prop="videoType"
          width="80"
          label="视频类型"></el-table-column>
        <el-table-column prop="plate"
          label="设备名称"></el-table-column>
        <el-table-column prop="plateColor"
          width="80"
          label="车牌颜色"></el-table-column>
        <el-table-column prop="groupName"
          width="160"
          label="车组"></el-table-column>
        <el-table-column prop="terminalNo"
          width="100"
          label="终端号"></el-table-column>
        <el-table-column prop="terminalType"
          width="100"
          label="终端类型"></el-table-column>
        <el-table-column prop="channel"
          width="60"
          label="通道号"></el-table-column>
        <el-table-column prop="startTime"
          width="160"
          label="开始时间"></el-table-column>
        <el-table-column prop="endTime"
          width="160"
          label="结束时间"></el-table-column>
        <el-table-column prop="playTime"
          width="100"
          label="时长（秒）"></el-table-column>
      </el-table>

      <div slot="footer"
        class="dialog-footer">
        <Export style="position:absolute;left:25px;"
          title="视频操作记录明细"
          :disabled="!data_info.length"
          exportUrl="videoOperate/operateRecordDetail.do"
          :params="{
            ...this.searchParams_info,
            pageSize:10000,
            pageNumber:1
          }"></Export>
        <el-pagination background
          :current-page="tablePage_info.pageNumber"
          :page-size="tablePage_info.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="tablePage_info.total"
          @current-change="changePage_info"
          style="text-align: center; margin: 10px 10px 0 10px;"></el-pagination>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
//选择时间
import DataRangePicker from "@/components/Date/DataRangePicker";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//搜索接口
import { getoperateRecord, getoperateRecordDetail } from '@/api/All-Interface.js';
//导出组件
import Export from '@/components/Export';
export default {
  // `name`与路由保持一致
  name: 'VideoOperationRecord',
  data() {
    return {
      searchParams: {
        vehicleId: 0,
        groupId: 0,
        flag: 0,
        startTime: '',
        endTime: '',
        pageSize: 50,
        pageNumber: 1,
        videoType: '',//视频类型
        channel: '',//选择的通道
        username: '',//用户名
      },
      //通道选择
      channlList: [
        { value: "", label: "全部" },
        { value: "1", label: "通道1" },
        { value: "2", label: "通道2" },
        { value: "3", label: "通道3" },
        { value: "4", label: "通道4" },
        { value: "5", label: "通道5" },
        { value: "6", label: "通道6" },
        { value: "7", label: "通道7" },
        { value: "8", label: "通道8" },
        { value: "9", label: "通道9" },
        { value: "10", label: "通道10" },
      ],
      data: [],//数据集合
      tableLoading: false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 50,
        total: 0,
      },
      //明细
      dialogVisible: false,//明细弹窗开关
      tableLoading_info: false,//用于明细窗口加载数据时的等待状态
      searchParams_info: {//明细请求数据集合
        pageSize: 50,
        pageNumber: 1,
      },
      data_info: [],//明细数据集合
      tablePage_info: {//明细分页
        //分页
        pageSizeOpts: [20, 50, 100],
        pageNumber: 1,
        pageSize: 50,
        total: 0
      },
    };
  },
  components: {
    DataRangePicker,
    SelectGroupTree,
    Export,
  },
  methods: {
    onChoiceDate(type, dates) {//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
        // this.onSearchClick(1);
      }
    },
    //车组车辆搜索框选择
    onSelect(node) {
      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.groupId = node.groupId || 0;
      if (node.type == 2) {
        this.searchParams.flag = 1;
      } else if (node.type == 1) {
        this.searchParams.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;

      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.flag = 1;
      this.searchParams.groupId = node.groupId || 0;
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.flag = 0;
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = 0;
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.onSearchClick(current);
    },
    //验证是否有输入车组或车辆
    verification() {
      if (this.searchParams.groupId == "" || this.searchParams.groupId == 0) {
        this.$message.info("请输入或者选择车组车辆");
        return false;
      } else {
        return true;
      };
    },

    //点击搜索
    onSearchClick(current) {
      this.data = [];
      if (!this.verification()) return;//验证是否有输入车组或车辆
      this.tableLoading = true;//用于加载数据 未完成时 的等待状态
      getoperateRecord({
        ...this.searchParams,
        pageNumber: current,
      }).then((res) => {
        this.tableLoading = false;
        if (res.flag == '1') {
          if (res.rows.length == 0) return this.$message.warning("数据为空");
          this.data = res.rows;
          this.tablePage.total = res.total;
          this.tablePage.pageNumber = current;
          this.searchParams.pageNumber = current;
        } else {
          return this.$message.warning("数据为空！");
        }
      });
    },
    //点击明细
    CheckInfo(item) {
      this.searchParams_info.vehicleId = item.row.vehicleId;
      this.searchParams_info.groupId = item.row.groupId;
      this.searchParams_info.startTime = this.searchParams.startTime;
      this.searchParams_info.endTime = this.searchParams.endTime;
      this.searchParams_info.channel = this.searchParams.channel;
      this.searchParams_info.username = this.searchParams.username;
      this.searchParams_info.videoType = this.searchParams.videoType;
      this.searchParams_info.flag = 1;
      this.searchParams_info.pageNumber = 1;
      this.getoperateRecordDetail(1);
    },
    //明细请求
    getoperateRecordDetail(current) {
      this.tableLoading_info = true;//开启等待转圈圈
      getoperateRecordDetail({
        ...this.searchParams_info,
        pageNumber: current,
        _qt: new Date().getTime(),
      }).then((res) => {
        this.tableLoading_info = false;//关闭等待转圈圈
        if (res.flag == 1) {
          this.data_info = res.rows;
          this.tablePage_info.total = res.total;
          this.tablePage_info.pageNumber = current;
          this.searchParams_info.pageNumber = current;
        } else {
          if (res.msg) {
            this.$message.warning(res.msg);
          } else {
            this.$message.warning("请检查是否有明细数据");
          };
          return;
        }
      });
      this.dialogVisible = true;//打开弹窗
    },

    //明细窗口被关闭时回调
    closeInfo() {
      this.data_info = [];
      this.dialogVisible = false;//关闭弹窗
    },
    //明细-分页改变每页数量
    pageSizeChange_info(pageSize) {
      this.tablePage_info.pageSize = pageSize;
      this.searchParams_info.pageSize = pageSize;
      this.changePage(1);
    },
    //明细-分页改变页数
    // changePage_info(current) {
    //   this.tablePage_info.pageNumber = current;
    //   this.searchParams_info.pageNumber = current;
    //   this.CheckInfo();
    // },
    changePage_info(current) {
      this.tablePage_info.pageNumber = current;
      this.searchParams_info.pageNumber = current;
      this.getoperateRecordDetail(current);
    },

  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.VideoOperationRecord {
  height: 100%;
  .el-header {
    margin: 0;
    padding: 0;
    .oper-btns {
      bottom: 8px;
      right: 15px;
    }
  }
  .el-main {
    height: calc(100% - 48px) !important;
    padding: 10px 20px 0 20px;
  }
  .el-dialog__header {
    background-color: #212d3e !important;
  }
  .pagination-pane {
    text-align: center;
  }
  .TreeWidth {
    ::v-deep .inline-input {
      width: 280px;
    }
  }
  .VideoInfo {
    ::v-deep .el-dialog__body {
      padding: 10px 30px 0px 30px;
    }
  }
  ::v-deep .el-dialog {
    margin-top: 10vh !important;
  }
}
</style>